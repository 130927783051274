import {
  TRANSITION_OFFER_ACCEPTED_BY_PROVIDER,
  TRANSITION_OFFER_DECLINED_BY_PROVIDER,
  TRANSITION_OFFER_MADE_BY_CUSTOMER,
  TRANSITION_OFFER_MADE_BY_PROVIDER,
} from '../../util/transaction';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

export const fetchCurrentUserRelevantTransactions = ({ currentUser }) => {
  return sdk.transactions
    .query({
      userId: currentUser?.id,
      lastTransitions: [
        TRANSITION_OFFER_MADE_BY_CUSTOMER,
        TRANSITION_OFFER_ACCEPTED_BY_PROVIDER,
        TRANSITION_OFFER_DECLINED_BY_PROVIDER,
        TRANSITION_OFFER_MADE_BY_PROVIDER
      ],
      include: ['listing', 'provider', 'customer', 'booking'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    })
    .then(res => {
      return res.data.data;
    })
    .catch(e => {
      return [];
    });
};

export const transactionToNotification = (transactions, currentUser) => {
  const currentUserId = currentUser?.id?.uuid;
  const userPrivateData = currentUser?.attributes?.profile?.privateData;
  const notificationsSeenByUser = userPrivateData?.seenNotifications || [];

  const notifications = transactions.map(t => {
    const transactionId = t?.id?.uuid;
    const transitions = t?.attributes?.transitions;
    const lastTransition = transitions[transitions.length - 1];
    const lastTransitionRole = lastTransition.by;
    const providerId = t?.relationships?.provider?.data?.id?.uuid;
    const customerId = t?.relationships?.customer?.data?.id?.uuid;
    const isOwnTransition =
      lastTransitionRole === 'provider'
        ? providerId === currentUserId
        : customerId === currentUserId;

    return {
      transactionId,
      lastTransition: lastTransition?.transition,
      createdAt: lastTransition?.createdAt,
      isOwnTransition,
      role: providerId === currentUserId ? 'provider' : 'customer',
      seen: !!notificationsSeenByUser.find(n => {
        return (
          n?.transactionId === transactionId && n?.lastTransition === lastTransition?.transition
        );
      }),
    };
  });

  const filteredNotifications = notifications.filter(n => !n.isOwnTransition).slice(0, 10);

  return filteredNotifications;
};

export const getNotificationMessage = transition => {
  let notificationMessage = 'We have an update on your transaction!';

  if (transition === TRANSITION_OFFER_MADE_BY_CUSTOMER) {
    notificationMessage = 'You have a new offer!';
  } else if (transition === TRANSITION_OFFER_ACCEPTED_BY_PROVIDER) {
    notificationMessage = 'Your offer has been accepted!';
  } else if (transition === TRANSITION_OFFER_DECLINED_BY_PROVIDER) {
    notificationMessage = 'Your offer has been declined!';
  } else if (transition === TRANSITION_OFFER_MADE_BY_PROVIDER) {
    notificationMessage = 'Your offer has been countered!';
  } 

  return notificationMessage;
};
