import React from 'react';
import css from './NotificationsDropdown.module.css';
import { getNotificationMessage } from '../../utils';

const NotificationsDropdown = ({
  notifications,
  history,
  setDropdownOpen,
  updateSeenNotifications,
}) => {
  if (notifications?.length === 0) {
    return <div className={css.wrapperNoNotif}>{"You don't have any notifications yet :("}</div>;
  }
  return (
    <div className={css.wrapper}>
      {notifications.map(n => {
        const message = getNotificationMessage(n?.lastTransition);
        const link = `/${n.role === 'provider' ? 'sale' : 'order'}/${n?.transactionId}/details`;
        const isSeen = n.seen;
        return (
          <div
            onClick={() => {
              if (!isSeen) {
                updateSeenNotifications();
              }
              history.push(link);
              setDropdownOpen(false);
            }}
            className={isSeen ? css.notificationItem : css.notificationItemNotSeen}
          >
            {message}
          </div>
        );
      })}
    </div>
  );
};

export default NotificationsDropdown;
