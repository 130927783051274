import React from 'react';
import css from './NotificationsBell.module.css';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useState } from 'react';
import NotificationsDropdown from './components/NotificationsDropdown/NotificationsDropdown';
import { useRef } from 'react';
import { useEffect } from 'react';
import { fetchCurrentUserRelevantTransactions, transactionToNotification } from './utils';
import { withRouter } from 'react-router-dom';

const NotificationsBellComponent = props => {
  const { onFetchCurrentUser, onUpdateProfile, currentUser, history } = props;
  const wrapperRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  //event listener for clicking outside of the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  //fetch transactions
  useEffect(() => {
    if (currentUser?.id?.uuid) {
      fetchCurrentUserRelevantTransactions(currentUser)
        .then(resp => {
          const notificationsData = transactionToNotification(resp, currentUser);
          setNotifications(notificationsData, currentUser);
        })
        .catch(e => console.log(e));
    }
  }, [currentUser]);

  const hasNewNotification = notifications?.length > 0 && notifications.find(n => !!!n?.seen);
  const notificationDot = hasNewNotification ? (
    <div className={css.notificationDot}>{notifications.length}</div>
  ) : null;

  const updateSeenNotifications = () => {
    onUpdateProfile({
      privateData: {
        seenNotifications: notifications?.map(n => {
          return {
            transactionId: n?.transactionId,
            lastTransition: n?.lastTransition,
          };
        }),
      },
    });
    setTimeout(() => onFetchCurrentUser(), 500);
  };

  return (
    <div className={css.wrapper} ref={wrapperRef}>
      <button
        className={css.button}
        onClick={() => {
          setDropdownOpen(!dropdownOpen);
        }}
      >
        <svg
          viewBox="0 0 448 512"
          width={18}
          height={20}
          className={hasNewNotification ? css.bellRings : css.bell}
          preserveAspectRatio="none"
        >
          <path
            style={{ fill: hasNewNotification ? '#ff0000' : '#4a4a4a' }}
            d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"
          ></path>
        </svg>
        {notificationDot}
      </button>
      {dropdownOpen && (
        <NotificationsDropdown
          notifications={notifications}
          history={history}
          setDropdownOpen={setDropdownOpen}
          updateSeenNotifications={updateSeenNotifications}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchCurrentUser: () => dispatch(fetchCurrentUser()),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const NotificationsBell = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(NotificationsBellComponent);

export default NotificationsBell;
